/*the complete project is in the following link:
https://github.com/vkive/coupon-code.git
Follow me on Codepen
*/
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'poppins', sans-serif;
  
}

.coupon-card{
  color: #fff;
  text-align: center;
  padding: 15px 30px;
  border-radius: 15px;
  box-shadow: 0 10px 10px 0 rgba(0,0,0,0.15);
  position: relative;

}
.logo{
  width: 50px;
  border-radius: 50px;
  margin-bottom: 10px;

}
.coupon-card h3{
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;

}
.coupon-card p{
  font-size: 12px;

}
.coupon-row {
  display: flex;
  align-items: center;
  margin: 10px auto;
  width: fit-content;

}
#cpnCode{
  border: 1px dashed #fff;
  padding: 5px 10px;
  border-right: 0;

}
#cpnBtn{
  border: 1px solid #fff;
  background: #fff;
  padding: 5px 10px;
  color: #7158fe;
  cursor: pointer;
}
.circle1, .circle2{
  background: #ffffff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

}
.circle1{
  left: -25px;
}
.circle2{
  right: -25px;
}
